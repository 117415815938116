import React from "react";

type EmptyStateProps = {
	icon?: React.ReactNode;
	title?: string;
	message?: string;
	className?: string;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
	icon,
	message = "There is no data to display at the moment.",
	title = "No Data Available",
	className = "",
}) => {
	return (
		<div
			className={`flex flex-col items-center justify-center text-center p-6 ${className}`}
		>
			{icon && <div className="mb-1 text-4xl text-gray-500">{icon}</div>}
			<h3 className="text-md font-semibold text-gray-700">{title}</h3>
			{message && <p className="text-gray-500 text-sm">{message}</p>}
		</div>
	);
};
